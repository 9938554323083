import Hash from 'object-hash'
import { path, mergeDeepRight } from 'ramda'

const Clone = function () {
  return JSON.parse(JSON.stringify.apply(this, arguments))
}

const isObject = function (val) {
  return Object.prototype.toString.call(val) === '[object Object]'
}

const SortByKey = key => (a, b) => {
  if (a[key] < b[key]) return -1
  if (a[key] > b[key]) return 1
  return 0
}

const ReduceArrayUniqueByKey = key => {
  key = key instanceof Array ? key : key.split('.')
  return (res, cur) => {
    if (!res.find(r => path(key, r) === path(key, cur))) res.push(cur)
    return res
  }
}

function PartialUpdate(out) {
  out = out || {}
  let strict = typeof arguments[arguments.length - 1] === 'boolean' && arguments[arguments.length - 1]

  const canWrite = (key, target, value) => {
    if (!strict) return true
    if (!(key in target)) return false // Prevent property adding
    if (isObject(target[key]) && [null, undefined].includes(value[key])) return false // Prevent object overrides

    return true
  }

  for (var i = 1, len = arguments.length; i < len; ++i) {
    var obj = arguments[i]

    if (!obj) {
      continue
    }

    for (var key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue
      }

      if (out[key] && isObject(obj[key])) {
        if (canWrite(key, out, obj)) out[key] = PartialUpdate(out[key], obj[key], strict)
        continue
      }

      if (canWrite(key, out, obj)) out[key] = obj[key]
    }
  }

  return out
}

function IconBinding(value) {
  if (!value) return
  if (value instanceof Array || typeof value === 'string') return { icon: value }
  return value
}

function setByPath(path = '', value, obj, original = obj) {
  if (!obj) return

  let getValue = (v, s) => {
    if (typeof v === 'function') return v(s, original)
    return v
  }

  if (typeof path === 'string') path = path.split('.')
  let current = path.shift()

  // Handle wildcards
  if (path[0] === '*' && obj[current] instanceof Array) {
    path = path.slice(1) // Remove wildcard from path
    if (!path.length) return obj[current].map(entry => getValue(value, entry))

    return obj[current].map(entry => setByPath([...path], value, entry, original))
  }

  if (path.length) return setByPath(path, value, obj[current], original)
  return (obj[current] = getValue(value, obj[current]))
}

function getByPath(path, obj) {
  if (!obj) return

  if (typeof path === 'string') path = path.split('.')
  let current = path.shift()

  if (!(current in obj)) return

  if (path.length) return getByPath(path, obj[current])
  return obj[current]
}

function Debounce(func, wait, immediate) {
  let timeout
  return function () {
    let context = this,
      args = arguments
    let later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

function getDimensions(url) {
  return new Promise((resolve, reject) => {
    if (!url) return reject(new Error('Url is required'))
    let img = new Image()
    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = reject
    img.src = url
  })
}

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function Capitalize(val = '') {
  return val.charAt(0).toUpperCase() + val.substring(1)
}

function Initials(val = '') {
  return val
    .split(' ')
    .map(p => p.substring(0, 1))
    .join('')
}

function ValueComp() {
  return {
    get() {
      return this.value
    },
    set(val) {
      this.$emit('input', val)
    },
  }
}

function CamelCase(value) {
  return value.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase()
  })
}

function Wait(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms)
  })
}

async function WaitUntil(func, options = {}) {
  let { interval = 200, limit = 50 } = options
  let count = 0
  let result = false

  while (count < limit) {
    count++
    if (func()) break
    await Wait(interval)
  }

  if (count >= limit) throw new Error('Could not resolve')
  return true
}

function ResidentialNumberPatternParser(pattern, input = '', replaceChar = '0') {
  if (input.toString) input = input.toString()

  let inputChars = input.split('')

  return pattern
    .split('')
    .map(char => {
      if (char !== replaceChar) return char

      if (!inputChars.length) return ''
      return inputChars.shift()
    })
    .join('')
}

function RemoveByValue(target, value, options = {}) {
  let { deep = false } = options
  let values = value instanceof Array ? value : [value]

  target = Clone(target)

  Object.keys(target).forEach(key => {
    if (values.includes(target[key])) delete target[key]
    if (isObject(target[key]) && deep) target[key] = RemoveByValue(target[key], values, options)
  })

  return target
}

async function Run(action, options = {}) {
  let { retryCount = 3, retryTimeout = 1000, onFailed = v => v } = options
  let attempts = 0,
    result,
    success = false,
    error

  while (attempts <= retryCount && !success) {
    attempts++

    try {
      result = await action()

      success = true
    } catch (err) {
      error = err
      await Wait(retryTimeout)
    }
  }

  if (!success) {
    onFailed(error)
    return { err: error }
  }
  return { err: null, item: result }
}

function Diff(a, b) {
  if (!b || !isObject(b)) return a

  let diffs = {}
  let key

  let arraysMatch = (a, b) => a.length === b.length && Hash(a.slice().sort()) === Hash(b.slice().sort())

  let compare = function (item1, item2, key) {
    // Get the object type
    let type1 = Object.prototype.toString.call(item1)
    let type2 = Object.prototype.toString.call(item2)

    // If items are different types
    if (type1 !== type2) return (diffs[key] = item2)

    // If an object, compare recursively
    if (type1 === '[object Object]') {
      let objDiff = Diff(item1, item2)
      if (!Object.keys(objDiff).length) return
      return (diffs[key] = objDiff)
    }

    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) diffs[key] = item2
      return
    }

    // Else if it's a function, convert to a string and compare
    if (type1 === '[object Function]' && item1.toString() !== item2.toString()) diffs[key] = item2

    // Otherwise, just compare
    if (item1 !== item2) diffs[key] = item2
  }

  // Loop through the first object
  for (key in a) {
    if (!a.hasOwnProperty(key)) continue
    compare(a[key], b[key], key)
  }

  // Loop through the second object and find missing items
  for (key in b) {
    if (!b.hasOwnProperty(key)) continue
    if (!a[key] && a[key] !== b[key]) {
      diffs[key] = b[key]
    }
  }

  // Return the object of differences
  return diffs
}

function hasDiff(a, b) {
  return Boolean(Object.keys(Diff(a, b)).length)
}

function PrefixObjectKeys(prefix, obj) {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [prefix + key, value]))
}

function ExtractString(str, pattern, options = {}) {
  let { group = 1 } = options
  let match = str.match(pattern)
  if (!match) return
  return match[group]
}

function EscapeRegex(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}

function Slugify(str) {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  var to = 'aaaaeeeeiiiioooouuuunc------'
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

function CopyToClipboard(str) {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject
      this.resolve = resolve
    })
  }
}

function getSecureRandomString(length, charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$&?') {
  let result = ''

  if (window.crypto && window.crypto.getRandomValues) {
    let values = new Uint32Array(length)
    window.crypto.getRandomValues(values)
    for (let i = 0; i < length; i++) {
      result += charset[values[i] % charset.length]
    }
    return result
  } else throw new Error("Your browser can't generate secure random numbers")
}

function ToArray(str) {
  if (str instanceof Array) return str
  if (typeof str === 'string') return [str]
  return []
}

function DownloadUrl(url, name) {
  fetch(url, { headers: { 'Cache-Control': 'no-cache' } })
    .then(res => res.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob)
      return trigger(blobURL, name)
    })
    .catch(err => {
      return trigger(url, name, '_blank')
    })

  let trigger = (href, name, target) => {
    const a = document.createElement('a')
    a.href = href
    a.style = 'display: none'
    if (target) a.target = target
    if (name) a.download = name

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
  }
}

function StripHTML(val) {
  return (val || '').replace(/<[^>]*>?/gm, '')
}

function ObjectToDotNotation(obj, current) {
  let res = {}

  for (let key in obj) {
    let value = obj[key]
    let newKey = current ? current + '.' + key : key
    if (isObject(value)) {
      res = { ...res, ...ObjectToDotNotation(value, newKey) }
    } else {
      res[newKey] = value
    }
  }

  return res
}

function InvertHexColor(hex) {
  if (hex.startsWith('#')) hex = hex.substring(1)
  return '#' + (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substring(1).toUpperCase()
}

function mergeAllDeepRight(...args) {
  const [a, b, ...rest] = args
  let result = mergeDeepRight(a, b)
  return rest.length ? mergeAllDeepRight(result, ...rest) : result
}



function toCurrency(
  value,
  locale = 'nb',
  currency = 'NOK',
  decimals = 0,
) {
  if (!value && value !== 0) return '-'
  return value.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
  })
}




export {
  mergeAllDeepRight,
  InvertHexColor,
  StripHTML,
  CopyToClipboard,
  ToArray,
  Slugify,
  EscapeRegex,
  Deferred,
  PrefixObjectKeys,
  RemoveByValue,
  ResidentialNumberPatternParser,
  Clone,
  PartialUpdate,
  IconBinding,
  setByPath,
  getByPath,
  Debounce,
  isObject,
  getDimensions,
  getCookie,
  Capitalize,
  ValueComp,
  Initials,
  CamelCase,
  Wait,
  SortByKey,
  WaitUntil,
  Run,
  Diff,
  hasDiff,
  ExtractString,
  ReduceArrayUniqueByKey,
  getSecureRandomString,
  DownloadUrl,
  ObjectToDotNotation,
  toCurrency
}
