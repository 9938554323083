import ValidatorMixin from './validator'

export default function (options) {
  return {
    ...ValidatorMixin,
    data() {
      return {
        validator: options,
      }
    },
  }
}
