import isFQDN from 'validator/lib/isFQDN'

export default Validator => {
  Validator.register(
    'domain',
    function (value) {
      return isFQDN(value)
    },
    ':attribute er ikke gyldig',
  )
}
